import { ArrowRightIcon, LockClosedIcon, GlobeAltIcon, CodeBracketIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { motion, Variants } from "framer-motion";
const capabilities = [
    {
        name: 'Web Development',
        description:
            'Proficient in web development using technologies such as React, Next.js, Bootstrap, Tailwind CSS, and Directus.',
        icon: GlobeAltIcon,
    },
    {
        name: 'Software Development',
        description:
            'Experienced in software development using frameworks like Delphi, VBA  and gaining knowledge of industry best practices.',
        icon: CodeBracketIcon,
    },
    {
        name: 'Mobile App Development',
        description:
            'Skilled in mobile app development using React Native, creating cross-platform applications.',
        icon: DevicePhoneMobileIcon,
    },
];

export default function About() {
    return (
        <div id='about' className="py-36 sm:py-36 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1 }}
                >

                    <motion.div >
                        <div className="mx-auto max-w-2xl lg:text-center">
                            <h2 className="text-lg font-semibold leading-8 text-indigo-600">Capabilities</h2>
                            <p className="mt-3 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                What I Can Do
                            </p>
                            <p className="mt-8 text-xl leading-8 text-gray-600">
                                Here are some of my key capabilities and skills.
                            </p>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1 }}
                >
                    <motion.div>
                        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                                {capabilities.map((capability, index) => (
                                    <motion.div
                                        key={capability.name}
                                        className="flex flex-col"
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        viewport={{ once: true }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                    >
                                        <dt className="flex items-center gap-x-3 text-xl font-semibold leading-7 text-gray-900">
                                            <capability.icon
                                                className="h-5 w-5 flex-none text-indigo-600"
                                                aria-hidden="true"
                                            />
                                            {capability.name}
                                        </dt>
                                        <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                            <p className="flex-auto">{capability.description}</p>
                                        </dd>
                                    </motion.div>
                                ))}
                            </dl>
                        </div>
                    </motion.div>
                </motion.div>


            </div>
        </div>
    );
}
