import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import CodeBlock from './CodeBlock';
import { delay, motion } from "framer-motion";
import Footer from './Footer';
import Technologies from './Technologies';
const projects = [
    {
        title: 'ATEIQHSE',
        href: 'https://ateiqhse.com',
        category: { href: 'https://ateiqhse.com' },
        description:
            "Revamped the company's website, and  made it more modern and user-friendly, using Nextjs/Strapi/Taiwlind",

        imageUrl:
            '/ateiqhse.png',

    },


    {
        title: 'Instagram Clone',
        href: 'https://github.com/VahanK/instagram-clone',
        category: { href: 'https://github.com/VahanK/instagram-clone' },
        description:
            'Instagram Clone Front-End using React Native/Expo.',

        imageUrl:
            '/instagram.png',
    },
    {
        title: 'Random SMMA UI',
        href: 'https://smma-ui.vercel.app/',
        category: { href: 'https://smma-ui.vercel.app/' },
        description:
            'Front-end of a random smma website using React/Tailwind/DaisyUI',

        imageUrl:
            '/smma.png',

    },
];

export default function Projects() {
    return (



        <div id='projects' className="relative overflow-visible h-screen bg-white">
            <div className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full" aria-hidden="true">
                <div className="mt-80 sm:mt-40 relative mx-auto h-full max-w-7xl">
                    <svg
                        className="absolute right-full translate-x-1/4 translate-y-1/4 transform lg:translate-x-1/2"
                        width={404}
                        height={784}
                        fill="none"
                        viewBox="0 0 404 784"
                    >
                        <defs>
                            <pattern
                                id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={784} fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)" />
                    </svg>
                    <svg
                        className="absolute left-full -translate-x-1/4 -translate-y-3/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
                        width={404}
                        height={784}
                        fill="none"
                        viewBox="0 0 404 784"
                    >
                        <defs>
                            <pattern
                                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                    </svg>
                </div>
            </div>

            <div className="relative pb-16 sm:pb-24">

                <main className="mx-auto max-w-7xl ">
                    <div className="relative px-6 pb-20 pt-16 lg:px-8 lg:pb-28 lg:pt-24">
                        <div className="absolute inset-0">
                            <div className="h-1/3  sm:h-2/3" />
                        </div>
                        <div className="relative mx-auto max-w-7xl">
                            <motion.div
                                initial={{ opacity: 0, top: 75, position: 'relative' }}
                                whileInView={{ opacity: 1, top: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5 }}
                            >

                                <motion.div >
                                    <div className="text-center">

                                        <p className="mt-3 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                            Projects
                                        </p>
                                        <p className="mt-8 text-xl leading-8 text-gray-600">
                                            Check out some of my recent projects and UI designs.
                                        </p>
                                    </div>
                                </motion.div>
                            </motion.div>


                            <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                                {projects.map((project, index) => (
                                    <motion.div
                                        key={project.name}
                                        className="flex flex-col h-full" // Set the container to take full height
                                        initial={{ opacity: 0 }}
                                        whileHover={{ scale: 0.99 }}
                                        whileInView={{ opacity: 1 }}
                                        viewport={{ once: true }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                    >
                                        <div
                                            key={project.title}
                                            className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg" // Set the card to take full height
                                        >
                                            <div className="flex-shrink-0">
                                                <img className="h-48 w-full object-cover" src={project.imageUrl} alt="" />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                                <div className="flex-1">
                                                    <p className="text-sm font-medium text-indigo-600">
                                                        <a href={project.href} className="hover:underline">
                                                            <p className=''>{project.category.href}</p>
                                                        </a>

                                                    </p>
                                                    <a href={project.href} className="mt-2 block">
                                                        <p className="text-xl font-semibold text-gray-900">{project.title}</p>
                                                        <p className="mt-3 text-base text-gray-500">{project.description}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                ))}
                            </div>


                        </div>
                    </div>
                    {/* <CodeBlock /> */}
                    <Technologies />
                    <Footer />
                </main>
            </div >
        </div >
    );
}



{/* <div className="mt-6 flex items-center">
                                                <div className="flex-shrink-0">
                                                    <a href={project.author.href}>
                                                        <span className="sr-only">{project.author.name}</span>
                                                        <img className="h-10 w-10 rounded-full" src={project.author.imageUrl} alt="" />
                                                    </a>
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        <a href={project.author.href} className="hover:underline">
                                                            {project.author.name}
                                                        </a>
                                                    </p>
                                                    <div className="flex space-x-1 text-sm text-gray-500">
                                                        <time dateTime={project.datetime}>{project.date}</time>
                                                    </div>
                                                </div>
                                            </div> */}
