/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { delay, motion } from "framer-motion";
const technologies = [
    {
        name: 'Web',
        description: 'Technologies I have used for web development include React JS, Next JS, Strapi, and Directus.',
    },
    {
        name: 'Backend',
        description: 'For backend development, I have experience with Node.js and SQL.',
    },
    {
        name: 'Mobile',
        description: 'In mobile app development, I have worked with React Native.',
    },
    {
        name: 'CSS Frameworks',
        description: 'I am familiar with CSS frameworks such as Tailwind CSS, Bootstrap, and Material UI.',
    },
    {
        name: 'Version Control',
        description: 'I use GitHub for version control and collaborative coding.',
    },
];

export default function Technologies() {
    return (
        <div id="technologies" className="mx-auto max-w-7xl px-8">
            <section aria-labelledby="technologies-heading" className="relative">
                <motion.div
                    initial={{ opacity: 0, top: 100 }}
                    whileInView={{ opacity: 1, top: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                >

                    <motion.div >
                        <div className="aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
                            <img
                                src="/undraw.svg"
                                alt="Black leather journal with silver steel disc binding resting on wooden shelf with machined steel pen."
                                className="h-full w-full object-contain object-center lg:h-full lg:w-full"
                            />
                        </div>
                    </motion.div>
                </motion.div>


                <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
                    <div className="lg:col-start-2">
                        <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900">Skills and Technologies</p>
                        <p className="mt-4 text-gray-500">
                            Here are some of the technologies and skills I have used and acquired for web and software development.
                        </p>
                        <dl className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 text-sm sm:grid-cols-2">
                            {technologies.map((technology, index) => (
                                <motion.div
                                    key={technology.name}
                                    className="flex flex-col"
                                    initial={{ opacity: 0 }}
                                    whileHover={{ scale: 0.99 }}
                                    whileInView={{ opacity: 1 }}
                                    viewport={{ once: false }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <div key={technology.name}>
                                        <dt className="font-medium text-gray-900">{technology.name}</dt>
                                        <dd className="mt-2 text-gray-500">{technology.description}</dd>
                                    </div>
                                </motion.div>
                            ))}
                        </dl>
                    </div>

                </div>
            </section>
        </div>
    )
}
