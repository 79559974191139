import React from 'react';
import { motion, Variants } from "framer-motion";
const Hero = () => {
    const name = "Vahan Kupelian";
    const title = "Web Developer";
    const description = "Welcome to my portfolio. I create beautiful and functional web experiences.";

    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
        >

            <motion.div >
                <div className="text-center pt-20 bg-white">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                        <span className="block xl:inline">Hi, I'm {name}</span>{' '}
                        <span className="block text-indigo-600 xl:inline">{title}</span>
                    </h1>
                    <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
                        {description}
                    </p>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Hero;
